.bpo-hero{
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
   
}
.bpo-hero img{
    width: 100%;
    height: 90vh;
    
}
.bpo-hero-content{
    width: 100%;
    height: 90vh;
    color: var(--white-color);
    background:rgba(115, 0, 66, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 2;
}


.bpo-btn1{
        background-color: var(--white-color);
        color: var(--black-color);
        margin-right: 30px;
        width: 13rem;  
          
}
.bpo-btn2{
    width: 13rem;
}

.bpo-top-content{
    width: 100%;
    height: 100%;
    line-height: 40px;
   
}
.bpo-link-card{
    background-color: var(--light-beetroot) !important;
}
.bpo-list li{
    margin-bottom: 10px;
    line-height: 30px;
}
.bpo-list li span{
    font-weight: bold;
}

.bpo-link li{
    margin-bottom: 15px;
}
.bpo-link li:hover{
    color: var(--primary-color);
    cursor: pointer;
}


.bpo-content h1{
    line-height:4rem;
}
.bpo-content p{
    line-height: 50px;
    letter-spacing: 2px;
}

@media screen and (max-width:576px) {
    .bpo-hero{
        height: 50vh;
    }
    .bpo-hero-content{
        height: 50vh;
    }
    .bpo-hero-content h1{
        font-size: 28px;
    }
    .bpo-btn1{
        margin-right: 10px;
        width: 10rem;
        padding:8px 12px !important;      
}
.bpo-btn2{
    width: 10rem;
    padding:8px 12px !important; 
}
.bpo-content h1{
    line-height:2.5rem;
}
.bpo-content p{
    line-height: 2;

}
    
}




