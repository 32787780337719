.tab-card{
    width: 20rem;
    background-color: #edede9 !important;
    border: none !important;
    border-radius: 0 !important;
    position: relative;
    transition: 0.5s;
    margin-right: 5px !important;

}
.tab-card p{
    color: gray !important;
}
.tab-card:hover{
    transition: 0.5s;
    transform: translateY(-5px);
}
.learn-more-btn{
    font-size: 12px;
    padding: 0;
    border: none;
    font-weight: bold;
}
.learn-more-btn:hover{
    color: #730042 ;
}

.tab-card h4{
    color: black;
}

.tab-card::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #730042;
    transition: width 0.4s ease;
  }

  .tab-card:hover::after{
    width: 100%;
  }

  @media screen and (max-width:576px) {
    .tab-card{
        min-width: 280px !important;
        
    }
  }