.youtube-card{
    overflow: hidden;
    position: relative;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    margin-bottom: 10px;
}
.youtube-card img{
    transition: 0.4s;
}

.youtube-card:hover img{
    scale: 1.1;
    transition: 0.5s;
}

.youtube-card i{
    font-size: 42px;
    cursor: pointer;
    position: absolute;
    bottom: 5%;
    left: 5%;
    
    
}
@media screen and (max-width:576px) {
       .youtube-card i{
        font-size: 32px;
        bottom: 3%;
        left: 3%;
        
        
    }
}