.industries h1{
    color: var(--primary-color);
    font-weight: bold;
    text-transform: uppercase;
    
}
.industries h1 span{
    display: block;
}

.industries .heading1{
    font-size: 3rem;
   line-height: 4rem;
}

.industries .heading2{
    color: var(--pink-color);
}

.industries p{
    line-height: 40px;
    font-size: 1.25rem;
}

@media screen and (max-width:576px) {
    .industries h1{
        font-size: 1.5rem;
    }
    
    
}