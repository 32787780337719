.ceo h6{
    color: var(--primary-color);
}

.ceo p{
   font-size: 1.25rem; 
   line-height: 2.3rem;
}

.ceo .link{
    color: var(--black-color);
}
.ceo .social-media{
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 10px;
}
.ceo .social-media i:hover{
    color: var(--primary-color);
}

@media screen and (max-width:576px) {
    .ceo p{
        font-size: 1rem;
    }
}