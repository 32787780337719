.slider-content h3{
    font-size: 5rem !important;
    margin-bottom: 20px; 
    color:var(--secondary-color); 
}

.slider-content p {
    font-size: 2rem; 
    color: var(--secondary-color);
    margin-bottom: 2rem;
}

.home-btn{
    background-color: var(--primary-color) !important;
    border: none !important;
    color: var(--secondary-color);
    padding:  8px 12px;
    border-radius:10px;
}



@media (max-width: 768px) {
    .carousel-background {
        height: 60vh;
    }

    .slider-content h3 {
        font-size: 3rem !important; 

    }
    .slider-content p{
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
        
    .carousel-background {
        height: 50vh;
    }
    
    .slider-content h3{
        padding:0 10px;
        font-size: 2rem !important;
    
}
.slider-content p{
    font-size: 1rem;
    padding: 0 20px;
    text-align: center;
}


    
}
