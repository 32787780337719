.ai-main-container{
    background-image: url("../../assets/images/AI/AI.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.ai-top-image-container{
    width: 100%;
    height:100vh;
    background-image: url("../../assets/images/AI/AI.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

}
.ai-top-image-container::after{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0, 0, 0, 0.6);
    z-index: 10;
}
.square{
    position: absolute;
    width: 50px;
    height: 50px;
    border: 5px solid var(--secondary-color);
    z-index: 20;
}
.square-1{
    top: 20%;
    left: 8%;
    animation: square 5s linear infinite;
}
.square-2{
    top: 50%;
    left: 20%;
    animation: square 7s linear infinite;
}
.square-3{
    bottom: 10%;
    left: 5%;
    animation: square 9s linear infinite;
}
.square-4{
    right: 40%;
    top: 80%;
    animation: square 11s linear infinite;
}
.square-5{
    top: 60%;
    right: 60%;
    animation: square 7s linear infinite;
}
.square-6{
    top: 20%;
    right: 10%;
    animation: square 13s linear infinite;
}
.square-7{
    top: 35%;
    right:25%;
    animation: square 12s linear infinite;
}
.square-8{
    bottom: 10%;
    right: 10%;
    animation: square 8s linear infinite;
}
.square-9{
    left: 50%;
    top: 25%;
    animation: square 9s linear infinite;
}
.square-10{
    top: 20%;
    left: 30%;
    animation: square 7s linear infinite;
}
.square-11{
    bottom: 40%;
    right:5%;
    animation: square 6s linear infinite;
}
.square-12{
    bottom: 10%;
    left:25%;
    animation: square 10s linear infinite;
}

@keyframes square{
    0%{
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }
    100%{
        transform: scale(1.2) translateY(-90px) rotate(360deg);
        opacity: 0;
    }
}

.ai-container{
    background-color: black !important; 
    margin-bottom: 200px;
    color:var(--secondary-color) !important;
    
}


.ai-top-image-container h1{
    font-size: 3.587rem;
    color: var(--secondary-color);
    position:absolute;
    top: 30%;
    left: 50px;
    z-index: 30;
}

.latest-at-section .link{
    display: none;
}

/* .ai-second-img{
    height: 500px;
} */

.ai-partners-img{
    height: 100px !important;
    margin-bottom: 50px;
}
.google-img, .ibm-img{
    margin-top: 20px;
    width: 150px;
    height: auto !important;

}
.microsoft-img{
        width: 220px;
}


@media screen and (max-width:575px) {
    .ai-top-image-container{
        height: 400px;
    }
    .ai-container{
        margin-bottom: 100px;
    }

     .ai-top-image-container h1{
        font-size: 2rem;
        color: var(--secondary-color);
        position:absolute;
        top: 35%;
        
    }
    .square{
        display: none;
    }
    .xs-card-center{
        display: flex;
        justify-content: center;
    }
    
    
}