.free-trial-btn{
    background-color: var(--primary-color);
}



.carporation-product .product-card{
    min-height: 200px;
    background-color: var(--white-color);
    border-radius: 10px;
    border: 2px solid var(--primary-color);
}
.carporation-product .product-card h6{
    color: var(--primary-color);
    font-weight: bold;
}
.carporation-product .product-card .link{
    color: var(--primary-color);
    text-decoration: none;
    position: relative;
}

.carporation-product .product-card .link::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; 
    width: 0;
    height: 2px; 
    background-color: var(--primary-color);
    transition: width 0.5s ease !important;
}

.carporation-product .product-card:hover{
    background-color:var( --light-beetroot);
}

.carporation-product .product-card .link:hover::after{
    width: 100%;
    
}




@media screen and (max-width:576px) {
    .corporation-card{
        margin-bottom: 1rem;
    }
}