*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
body{
  padding-top:80px;
}
::-webkit-scrollbar{
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track{
  background-color:#f9f8f2;
}

::-webkit-scrollbar-thumb{
  background-color: #730042;
  border-radius: 6px;
  border: 2px solid #f9f8f2;
}


:root{
  --primary-color:#730042;
  --secondary-color:#f9f8f2;
  --white-color:#fff;
  --light-beetroot:rgba(228, 70, 159, 0.1);
  --pink-color:#cc498a;
  --black-color:#000;
  
}


.text-dark-beetroot{
  color: var(--primary-color);
}

.bg-light-beetroot{
  background-color: rgba(233, 43, 150, 0.1);
}
.bg-white{
    background-color: #fff;
}



p{
  line-height: 25px;
}

.main-btn{
  padding: 15px 30px ;
  border: none;
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);;
  border-radius: 50px;
  transition: 0.5s;
}
.main-btn:hover{
  color: var(--black-color);
  background-color:var(--secondary-color);;
  transition: 0.5s;
}

.main-btn-second{
    padding: 10px 30px;
    border: none;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 50px;
    transition: 0.5s;
}
.main-btn-second:hover{
  background-color: var(--primary-color);
  color: var(--secondary-color);
}



.main-heading{
  color: var(--primary-color);
  text-align: center;
}

.main-hr{
  border: 0 !important;
  height: 3px !important;
  background-color: var(--primary-color) !important;
  width: 20% !important;
  margin: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
  opacity: 1;
}


.font-size{
  font-size: 1.25rem;
}

.section{
 margin-top: 100px;
  background-color: var(--white-color);
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    
}

@media screen and (max-width:576px) {
  
  .main-btn-second{
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .font-size{
    font-size: 1rem;
  }
}



