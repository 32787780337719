
/* Navbar styles */
.custom-navbar {
  background-color: var(--white-color);
  z-index: 1000;
  font-weight: 500;
  padding-right: 30px;
  background-color: var(--white-color); 
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-navbar.scrolled {
  background-color: var(--white-color);
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}


.nav-link{
  color: var(--primary-color) !important;
  margin: 0 10px;

}



.custom-navbar .nav-link:hover,
.custom-navbar .nav-link.active {
  color: var(--primary-color) !important;
}

.dropdown-arrow {
  margin-left: 5px;
  transition: transform 0.3s;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

/* Submenu */
.submenu-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 80px;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
 
}

.submenu-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
 

}

.submenu-content ul {
  list-style: none;
}

.submenu-content ul li {
  margin-bottom: 5px;
  cursor: pointer;
}

.submenu-content h4 {
  margin-bottom: 20px;
  color: var(--primary-color);
}

.submenu-content h4 i{
  display: none;
}

.submenu-content a{
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.submenu-content a:hover {
  color: var(--primary-color);
}

/* Search bar */
.search-bar {
  display: flex;
  align-items: center;
  position: relative;

}

.search-input {
  border: 2px solid var(--primary-color) !important;
  border-radius: 4px;
  padding: 5px 10px;
  width: 0;
  transition: width 0.3s;
  background-color: transparent;
  margin-left: 20px;
}


.search-icon-btn {
  background-color: transparent !important;
  cursor: pointer !important;
  color: var(--primary-color) !important;
}

.search-icon-btn {
  outline: none; 
  box-shadow: none;
}

.search-icon-btn:focus {
  outline: none;
  box-shadow: none !important;
}

.custom-navbar {
  background-color: var(--white-color);
  transition: background-color 0.3s ease;
  box-shadow: none;
}

.custom-navbar.scrolled {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.search-icon-btn {
  border: none !important;
}


.search-bar-container {
  position: fixed;
  top: 70px; 
  left: 0;
  width: 100%;
  background-color:var(--white-color);
  z-index: 9999;
  padding: 10px;
  display: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.search-bar-container.visible {
  display: block;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--white-color);
  border-radius: 4px;
  font-size: 16px;
}

.capabilities-container{
  display: grid;
  grid-template-columns: auto auto;
}
.sub-menu-digital-container{
  grid-row: 1/3;
}


.navbar-toggler{
  font-size: 32px !important;
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  padding:5px 10px !important;  
  cursor: pointer;
 
  
  
}
.close-btn{
  color: var(--primary-color);
  font-size: 32px;
  margin-right: 10px;
  cursor: pointer;
  
 
}


@media (max-width: 992px) {
  .search-icon-btn {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .custom-navbar .nav-link {
    font-size: 12px;
    padding: 5px 10px;
  }
}


@media (max-width: 1024px) {
  .custom-navbar .nav-link {
    font-size: 12px;
    padding: 5px 10px;
  }

  .submenu-content h1 {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .search-bar {
    justify-content: flex-start;
  }

  .submenu-overlay {
    height: auto;
  }
}

@media (max-width: 576px),(max-width: 768px)  {
  .menu-link-container{
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--white-color);
    width: 100%;
    padding: 20px 0;
    transition:0.5s !important;

  }

  .navbar-logo{
    padding-left: 0;
  }
  .menu-link-container .nav-link{
    font-size: 16px;
    padding: 15px 0 !important;
    border-bottom: 1px solid var(--primary-color);
    margin: 0 20px !important;
  }

  .submenu-overlay {
    width: auto;
    height: 100vh;
    position: fixed;
    top:80px;
    left: 35% ;
    right:0px ;
    z-index:1500;
   
   
  }

 
  .submenu-content ul{
    padding-left: 0;
  }
  .submenu-content ul li{
    font-size: 14px;
  }

  .capabilities-container{
    height: 100vh;
    display: block;
    overflow-y: scroll;
    padding-bottom: 120px;
    

  }
  .capabilities-container::-webkit-scrollbar {
    width: 0px;
  }  
  .submenu-content h4{
    font-size: 16px;
    position: relative;
    
  }

 

  .capabilities-container h4{
    cursor: pointer;
   
  }

  

  .hide{
    display: none;
  }

  .submenu-content h4 i{
    display: inline-block;
  }
  

  
  

 

}
