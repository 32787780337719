.video-container {
    position: relative;
    height: 90vh; /* Full viewport height */
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1; 
  }
  
  .video-container .content{
    position: relative;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.6);
    z-index: 1;
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  