.accordion-item{
    margin-bottom: 1rem;
   
}
.accordion-header{
    padding: 5px 16px;
    border-radius: 10px;
    background-color: var(--primary-color);
    color: var(--white-color);
    cursor: pointer;
   

}
.accordion-header i{
    font-size: 32px;
}







