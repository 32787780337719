.overview{
    background-image: url('../../assets/images/Company\ Overview/Hero.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.overview-content p{
    line-height: 2.5rem;
}


.cloud-second-image{
    width: 100%;
    height: 80vh;
    background-image: url('../../assets/images/Cloud/cloud.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
.cloud-second-image-content{
    max-width: 500px;
    padding:20px;
    position: absolute;
    top: 20%;
    left: 15%;
    background-color: var(--white-color);

}

@media screen and (max-width:576px) {
   
    .cloud-second-image{
        height: 30vh;
        background-image: none;
    }
    .cloud-second-image-content{
        width: 100%;
        padding:15px;
        top: 10%;
        left: 0;
    
    }
}