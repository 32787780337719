.main-card{
    max-height: 380px !important;
    overflow: hidden;
    transition: 0.5s;
    position: relative;
    border-radius: 0 !important;
    border: none !important;
}

.main-card::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    
}

.main-card p{
    color:var(--white-color) !important;
    z-index: 100;
   
}

.image-container{
    width: 100%;
    height: 100%;
}


.card:hover .card-heading{
    z-index: 500;
}

.card-img{
    transition: 0.5s;

}

.card-img{
    border-radius: 0 !important;   
}

.card:hover .card-img{
    transform: scale(1.1);
    transition: 0.5s;   
}

.card-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-heading{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 30px 20px;
    color: var(--white-color) !important;
    z-index: 700;
    
}

.card-content{ 
    width: 100%;
    height: 100%; 
    position: absolute;
    top: 0;
    right:-100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content:end;
    transition: 0.5s;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.7);
}

.main-card:hover .card-content{
    right: 0;
    transition: 0.5s;
    z-index: 200;
}


.link{
    text-decoration: none;
    padding: 8px 18px !important;
}
.main-card .link:hover{
    color: var(--black-color) !important;
}


@media screen and (max-width:575px) {
    .main-card{
        max-width: 350px;
    }
    .card-content{
        justify-content:space-around;

    }
    
    
}



