.leadership-card{
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 5px;
}


.linkedin_link{
    font-size: 32px;
    color: var(--primary-color);
}


.link{
    text-decoration: none;
}

.link:hover{
    color: var(--white-color);
}

@media screen and (max-width:576px) {
    .leadership-card{
        text-align: center;
    }
    .leadership-card h4{
        margin-top: 1rem;
    }
}