.image-section{
    height: 400px;
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));

}
.image-section img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.image-section .content-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.image-section .content-container .content{
    position: absolute;
    bottom: 20px;
    color: var(--secondary-color);
}

@media screen and (max-width:576px) {
    .image-section{
        height: 250px;
        background-color: rgba(0,0,0,0.5);
    }
    
}

