
.product-slider .carousel-background{
    height: 50vh;
    
}
.product-slider .slider-content{
    background-color: transparent !important;
 
}

.product-slider .slider-content p{
    font-size: 16px !important;
    color: black !important;
}

.carousel-indicators button{
    background-color: var(--primary-color) !important;
}


@media screen and (max-width:768px) {
    .product-slider .carousel-background{
     height: 280px;
     overflow: hidden;
    }
 
     
 }
@media screen and (max-width:576px) {
   .product-slider .carousel-background{
    height: 250px;
    overflow: hidden;
   }

    
}