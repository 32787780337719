.ai-card{
    max-width: 320px;
    min-height:350px;
    color: #f9f8f2 !important;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.1) !important;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;


}
.ai-card h4,p{
    z-index: 100;
}

.circle{
    width: 220px;
    height: 220px;
    position: absolute;
    top: -100px;
    right: -100px;
    background-color: #730041;
    border-radius: 50%;
    transition: 0.5s;
}

.ai-card:hover .circle{
    transform: scale(5);
}








