
.top-content h1{
    color: var(--primary-color);
    word-spacing: 10px;
    font-weight: 600;
}

.top-content p{
    word-spacing: 10px;
    color: var(--pink-color);
    font-size: 1.25rem;
}

@media screen and (max-width:576px){
    .top-content p{
        font-size: 1rem;
    }
}