.feature-list{
    border-left:  4px solid var(--primary-color);
}

.feature-list li{
    margin-left: 50px;
    margin-bottom: 15px;
    font-size: 1.25rem;
}
.feature-list li span{
    color: var(--black-color);
    font-weight: bold;
}

@media screen and (max-width:576px) {
    .feature-list{
        border-left: none;
      
    }
    .feature-list li{
        margin-left: 20px;
        font-size: 1rem;
       
    }
}