.map{
    padding: 0px !important;
}

.responsive-map iframe{
    width: 100%;
    height: 400px;
}

/* Mobile Screen  */
@media screen and (max-width:575px){
    .responsive-map{
        height: auto;
    }
}