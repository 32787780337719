.hero{
    width: 100%;
    height: 90vh;
    position: relative;  
  }

  .hero-img{
    position: absolute;
    width: 100%;
    height: 90vh;
    z-index: -1;
  }
.hide-image{
  display: none;
}
  .hero-content-container{
    z-index: 1;
    color: var(--white-color);
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
   
  }
  
  .hero-content{
    width: 50%;
    position: absolute;
    top: 20%;
    left: 15%;   
  }


  .hero-heading{
    font-size: 3.2rem;
    text-transform: capitalize;
    font-weight: bold;
  }


  @media screen and (max-width:576px) {
    .hero{
      height: 60vh;
   
    }

    .hero-img{
      height: 60vh;
      
    }

    .hero-heading{
      font-size: 32px;
    }
    .hero-content{
      width: 90%;
      left: 5%; 
      right: 5%;
    }

}