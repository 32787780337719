
.it-image-container{
    border-radius: 10px;
    overflow: hidden;
    height: 400px;
    position: relative;
}

.it-img-content-container{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: var(--secondary-color);
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
}
.it-img-content{
    position: absolute;
    bottom: 50px;
    padding-left: 20px;
}


.our-approach-content{
    text-align: center;
}
.our-approach-content i{
    width: 100%;
    font-size:92px;

}

.benefits-list{
    border-left:  4px solid var(--primary-color);
    margin-left: 20px;

}
.benefits-list li{
    margin-left: 50px;
    margin-bottom: 15px;
}

@media screen and (max-width:576px) {
   
    .it-content-container{
        width: 100%;
        height: 100%;
    }

    .it-content{
        position: absolute;
        top: 50%;
        left: 50%;
      transform: translate(-50%, -50%);
    }

    .it-img-content-container{
        background-color: rgba(0, 0, 0, 0.5);
        
    }
    
    .it-image-container{
        height: auto;
    }
    .it-img-content{
        bottom: 20px;
    }

    .our-approach-heading{
        text-align: center;
    }

    .benefits-list{
        margin-left: 10px;
    }
    .benefits-list li{
        margin-left: 20px;
        margin-bottom: 10px;
    }
}


