.office-search{
    position: relative;
}
.office-search input{
    padding: 10px;
    border: 1px solid var(--primary-color);
}
input,textarea,select{
    border: 1px solid var(--primary-color) !important;
}

.office-search i{
    position: absolute;
    right: 0;
    top: 5px;
    right: 50px;
    font-size: 24px;
    color: var(--primary-color);
    cursor: pointer;
}

.form-container{
    box-shadow: 0 0 5px;
    position: relative;
   
}

@media screen and (max-width:576px) {
    .contact-image{
        display: none;
    }
    .form-container{
        box-shadow:none;
       
    }
}









