.eduficera-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.5s;
    margin: 20px 0;
   
}
.eduficera-content:hover{
    transform: translateY(-8px);
    transition: 0.5s;
}