.main-tab-container{
    position: relative;
}
.ds-icon{
    text-align: end;
}
.main-tab-container i{
    border-radius: 50%;
    cursor: pointer;
    padding: 8px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
    font-size: 22px;
    margin: 5px;

}

.home-nav{
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    margin-bottom: 1rem !important;
    overflow-x: scroll;
    padding: 5px 10px;
    transition: 0.5s;
    position: relative;
    
}






.home-nav .nav-link{
    padding: 5px 10px !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    border-radius:20px ;
    margin: 5px !important;
   white-space: nowrap;
}

.home-nav .nav-link.active{
    background-color: var(--primary-color);
    color: var(--white-color) !important;
    padding: 5px 10px !important;
    border-radius: 20px;
    transition: background-color 0.5s;

}

.nav::-webkit-scrollbar{
    display: none;
}


.tab-content{
    overflow-x:scroll;
}
.tab-content::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:576px) {
   
   
}

