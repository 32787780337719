.corporation-card{
    min-height: 325px;
    background-color: var(--white-color);
    border-radius: 10px;
    transition: 0.5s;
    margin-bottom: 1.5rem;

}

.corporation-card:hover{
    background-color: rgba(115, 0, 66,0.1);
    transition: 0.5s;
}

.eng-card{
    text-align: center;
    height: 380px !important;
    
}