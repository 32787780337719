.cloud-card{
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   padding:0 1rem;
   margin-bottom: 20px;
   position: relative;
   overflow: hidden;
   background-image: linear-gradient(var(--light-beetroot),rgb(138, 53, 101));
   border: none !important;
}












